
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
.newsletterWrapper {
  background-color: $color-indonesia-whisper;
  display: flex;
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  gap: $space-sm;
}

.textAndBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 2 1 65%;
  flex-direction: column;
  gap: $space-sm;
}

.paragraph {
  margin-bottom: 0;
}

.form {
  flex: 1 1 35%;
}

.submitButton {
  margin: $space-md 0 $space-xs;
}

.submitFeedback {
  margin: $space-xs 0;
}

.privacyLink {
  display: inline-block;
  padding-top: $space-xs;
}

@include media-breakpoint-up(md) {
  .form {
    &.isRaffle {
      flex: 0 1 35%;
    }
  }

  .textAndBadge {
    flex-direction: row;
  }

  .bannerContainer {
    flex-direction: row;
    align-items: center;
  }
}
